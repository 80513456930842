import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardText,[_c(VRow,{attrs:{"align-content-space-between":""}},[_c(VFlex,[_c(VSelect,{attrs:{"label":"Rolle","items":_vm.roleFilterItems,"item-value":"id","item-text":"name"},model:{value:(_vm.roleId),callback:function ($$v) {_vm.roleId=$$v},expression:"roleId"}})],1),_c(VSpacer),_c(VFlex,[_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Søk","placeholder":"Søk etter navn eller ID...","single-line":"","clearable":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.search = ''}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.filteredContacts,"item-key":"contactId","loading":_vm.loading,"search":_vm.search,"sort-by":"sortName","footer-props":{
        itemsPerPageOptions: [5, 10, 20, 50]
      },"items-per-page":10,"dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('tr',{on:{"click":function($event){$event.stopPropagation();return _vm.showItemDialog(item)}}},[_c('td',[_vm._v(_vm._s(item.contactId))]),_c('td',[_vm._v(_vm._s(item.sortName))]),_c('td',[_vm._v(_vm._s(_vm.formatRoles(item.roles)))]),_c('td',[_vm._v(_vm._s(_vm.formatDateTime(item.lastUpdated)))]),_c('td',[_c(VIcon,{attrs:{"color":"warning"},on:{"click":function($event){$event.stopPropagation();return _vm.remove(item)}}},[_vm._v(" mdi-delete-forever ")])],1)])]}}])},[_c(VProgressLinear,{attrs:{"color":"accent","indeterminate":""},scopedSlots:_vm._u([{key:"progress",fn:function(){return undefined},proxy:true}])})],1)],1),_c(VCardActions,[_c(VSpacer),_c('DialogEdit',{attrs:{"title":"Opprett kontakt","create-model":_vm.createNewModel,"save":_vm.save},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c(VIcon,_vm._g({},on),[_vm._v(" mdi-playlist-plus ")])]}},{key:"edit",fn:function(ref){
      var model = ref.model;
return [_c('BasedataContactEdit',{attrs:{"model":model}})]}}])})],1),_c('DialogEdit',{ref:"itemDialog",attrs:{"title":"Kontakt","edit-title":"Rediger kontakt","save":_vm.save},scopedSlots:_vm._u([{key:"view",fn:function(ref){
      var model = ref.model;
return [_c('BasedataContactView',{attrs:{"model":model}})]}},{key:"edit",fn:function(ref){
      var model = ref.model;
return [_c('BasedataContactEdit',{attrs:{"model":model}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }